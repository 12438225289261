// action types
import SecureApiService from "../secureapi.service";
import { SET_MESSAGE } from "@/core/services/store/message.module";

export const PULL_FIELDS = "[get]relevance_tuning";
export const PREVIEW_FIELDS = "[post]relevance_tuning/preview";
export const PREVIEW_BOOSTIFY = "[post]boostify/preview";
export const PUT_BOOSTIFY = "[put]boostify/preview";
export const PUT_FIELDS = "[put]relevance_tuning/preview";

export const FIELDS_DATA = "fields_data";
export const PREVIEW_DATA = "preview_data";

export const SET_ERROR = "setError";
export const SET_INFO = "setInfo";

export const REFRESH = "refresh";
export const REFRESH_ACTION = "refreshAction";

class Field {
  constructor(
    id,
    collection_id,
    name,
    type,
    searchable,
    weight,
    boosts,
    wildcard_enable,
    wildcard,
    phrase_enable,
    phrase,
    user_phrase_check,
    boostable,
    fuzzy,
    shingle,
    stemmer
  ) {
    this.field_id = id;
    this.collection_id = collection_id;
    this.field_name = name;
    this.type = type;
    this.searchable = searchable;
    this.weight = weight;
    this.wildcard_enable = wildcard_enable;
    this.wildcard = wildcard;
    this.phrase_enable = phrase_enable;
    this.phrase_weight = phrase;
    this.boosts = boosts;
    this.user_phrase_check = user_phrase_check === "1";
    this.boostable = boostable;
    this.fuzzy = fuzzy;
    this.shingle = shingle;
    this.stemmer = stemmer;
  }
}

export class Boosts {
  constructor(value, boosts, based) {
    this.type = "value";
    this.value = value;
    this.boost = boosts;
    this.based = based;
  }
}

export class BoostFunction {
  constructor(_function, operation, boost) {
    this.type = "functional";
    this.function = _function;
    this.operation = operation;
    this.boost = boost;
  }
}

const state = {
  errors: [],
  hasMessage: false,
  loading: false,
  message: "",
  fields: [],
  previews: [],
  took_time: 0
};
const getters = {};

const actions = {
  [REFRESH](context) {
    context.commit(REFRESH_ACTION);
  },
  [PULL_FIELDS](context, collection) {
    state.loading = true;
    return SecureApiService.get("relevance_tuning", collection.id)
      .then(({ data }) => {
        context.commit(FIELDS_DATA, data.data);
        context.commit(REFRESH_ACTION);
      })
      .catch(({ response }) => {
        if (response && response.msg) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, {
            err: 1,
            msg: "Internal error, please try again a few minutes later."
          });
        }
      });
  },
  [PREVIEW_FIELDS](context, data) {
    return new Promise(resolve => {
      SecureApiService.post("relevance_tuning/preview", data)
          .then(({ data }) => {
            if (data.err === 1) {
              context.commit(SET_MESSAGE, data);
              return;
            }

            context.commit(PREVIEW_DATA, data);
            context.commit(SET_MESSAGE, data);
            resolve(data.data);
          })
          .catch(() => {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: "Internal error, please try again a few minutes later."
            });
          });
    });
  },
  [PREVIEW_BOOSTIFY](context, data) {
    state.loading = true;

    return new Promise(resolve => {
      SecureApiService.post("boostify/preview", data)
          .then(({ data }) => {
            if (data.err === 1) {
              context.commit(SET_MESSAGE, data);
              return;
            }

            context.commit(PREVIEW_DATA, data);
            context.commit(SET_MESSAGE, data);
            resolve(data.data);
          })
          .catch(() => {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: "Internal error, please try again a few minutes later."
            });
          });
    });
  },
  [PUT_FIELDS](context, data) {
    return new Promise(resolve => {
      SecureApiService.put("relevance_tuning", data)
          .then(({ data }) => {
            if (data.err === 1) {
              context.commit(SET_MESSAGE, data);
              return;
            }

            context.commit(SET_MESSAGE, data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            if (response.data.errors) {
              context.commit(SET_MESSAGE, response.data);
            } else {
              context.commit(SET_MESSAGE, {
                err: 1,
                msg: "Internal error, please try again a few minutes later."
              });
            }
          });
    });
  },
  [PUT_BOOSTIFY](context, data) {
    return new Promise(resolve => {
      SecureApiService.put("boostify", data)
          .then(({ data }) => {
            if (data.err === 1) {
              context.commit(SET_MESSAGE, data);
              return;
            }

            context.commit(SET_MESSAGE, data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            if (response.data.errors) {
              context.commit(SET_MESSAGE, response.data);
            } else {
              context.commit(SET_MESSAGE, {
                err: 1,
                msg: "Internal error, please try again a few minutes later."
              });
            }
          });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.loading = false;
    state.errors = error;
  },
  [SET_INFO](state, message) {
    if (message) {
      state.message = message;
      state.hasMessage = true;
    }
  },
  [FIELDS_DATA](state, data) {
    state.loading = false;
    state.fields = [];
    data.forEach(function(field) {
      let boosts = [];
      field.boosts.forEach(function(item) {
        switch (item.type) {
          case "value":
            boosts.push(new Boosts(item.value, item.boosts, item.based));
            break;
          case "functional":
            boosts.push(
              new BoostFunction(item.function, item.operation, item.boosts)
            );
            break;
        }
      });

      state.fields.push(
        new Field(
          field.id,
          field.collection_id,
          field.field_name,
          field.type,
          field.searchable,
          field.weight,
          boosts,
          field.wildcard != 0,
          field.wildcard,
          field.phrase_weight != 0,
          field.phrase_weight,
          field.user_phrase_check,
          field.boostable,
          field.fuzzy,
          field.shingle,
          field.stemmer
        )
      );
    });
  },
  [PREVIEW_DATA](state, data) {
    state.loading = false;
    state.previews = data.data;
    state.took_time = data.res.took.final;
  },
  [REFRESH_ACTION](state) {
    state.errors = [];
    state.hasMessage = false;
    state.loading = false;
    state.message = "";
    state.searchString = "";
    state.previews = "";
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
  Boosts
};
