<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-form ref="form" lazy-validation>
       <v-card-title>
         <h3>Select Object for Boost</h3>
         <small>Choose the object you want to assign a boost to optimize their relevance in search results.</small>

       </v-card-title>
        <v-card-text>
          <v-autocomplete
            :loading="loading"
            v-model="selectedItem"
            label="Search an object"
            :items="items"
            required
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="close">Back</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary dark large" ref="submit" @click="save"
            >Take an object
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
import { PULL_SCHEMA_AUTOCOMPLETE } from "@/core/services/store/schema_autocomplete.module";

export default {
  data() {
    return {
      selectedItem: null,
      dialog: false
    };
  },
  props: {
    schema: {
      type: Object,
      required: true
    },
    callback: {
      type: Function,
      required: true
    }
  },
  methods: {
    save() {
      this.callback(this.schema, this.selectedItem);
      this.schema = null;
      this.dialog = false;
    },
    close() {
      this.callback(this.schema, "");
      this.schema = null;
      this.dialog = false;
    },
    reloadData() {
      this.items = [];
      this.selectedItem = null;
      this.$store.dispatch(PULL_SCHEMA_AUTOCOMPLETE, this.schema);
    }
  },
  watch: {
    changeSchema() {
      if (this.schema && this.schema.field_name) {
        this.reloadData();
        this.dialog = true;
      }
    }
  },
  computed: {
    changeSchema() {
      return this.schema;
    },
    ...mapState({
      items: state => state.schema_autocomplete.items,
      loading: state => state.schema_autocomplete.loading,
      message: state => state.message.message
    })
  }
};
</script>
