<template>
  <div>
    <div class="row">
      <div class="col-xl-6">
        <h1 class="card-label">
          {{ $t("boost_manager.title") }}
          <span class="d-block text-muted pt-2 font-size-lg">{{ $t("boost_manager.subtitle") }}</span>
          <small>{{ $t("boost_manager.short_description") }}</small>
        </h1>
      </div>
      <div class="col-xl-6 ">
        <!--begin::Button-->
        <v-btn
          :loading="loading"
          @click.prevent="save"
          color="primary"
          href="#"
          class="btn btn-primary font-weight-bolder float-right "
        >
        {{ $t("boost_manager.toolbar.action_save") }}</v-btn
        >
        <a
          @click.prevent="reloadData"
          href="#"
          class="btn btn-warning font-weight-bolder float-right mr-2"
        >
           {{ $t("boost_manager.toolbar.action_discard_changes") }}</a
        >
        <v-flex class="float-right mr-2">
          {{ $t("boost_manager.toolbar.collection") }}:
          <collection_select />
        </v-flex>
        <!--end::Button-->
      </div>
    </div>
    <div class="row">
      <div class="col-xl-8">
        <h2>{{ $t("boost_manager.manage.title") }} ({{selectedCollection.name}})</h2>
        <p>{{ $t("boost_manager.manage.subtitle") }}</p>

        <v-progress-linear
            v-if="loading"
            color="blue"
            indeterminate
        ></v-progress-linear>
        <v-container v-if="filteredList.length>0">

              <div v-for="field in filteredList" :key="field.field_name">
                <b-card
                    no-body
                    v-if="(enableBoost && field.boostable)"
                >
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div
                        class="d-flex align-items-center bg-light rounded p-5"
                        v-b-toggle="'accordion-' + field.field_name"
                        variant="info"
                        @click.prevent=""
                    >
                      <!--begin::Icon-->
                      <span class="svg-icon svg-icon-warning mr-5">
                    <span class="svg-icon svg-icon-lg"> </span>
                  </span>
                      <!--end::Icon-->
                      <!--begin::Title-->
                      <div class="d-flex flex-column flex-grow-1 mr-2">
                        <a
                            href="#"
                            class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                        >{{ field.field_name }}</a
                        >
                      </div>
                      <span
                          v-if="field.boosts.length > 0"
                          class="label label-light-primary label-lg  mr-2  label-inline font-weight-bold py-4"
                      ><span class="fas fa-angle-double-up mr-2"></span>{{ field.boosts.length }}</span>
                      <!--end::Lable-->

                    </div>
                  </b-card-header>
                  <b-collapse
                      v-bind:id="'accordion-' + field.field_name"
                      visible
                      accordion="my-accordion"
                      role="tabpanel"
                  >
                    <b-card-body
                        v-if="(enableBoost && field.boostable) && field.boosts.length>0">
                        <v-row class="row mb-5">
                          <v-col cols="12" lg="6">
                            <input
                                class="form-control"
                                type="text"
                                v-if="field.boosts.length>3"
                                v-bind:placeholder="'Filter ' + field.boosts.length + ' boosts...'"
                                v-model="filterBoosts[field.field_name]"
                            />
                          </v-col>

                          <v-col cols="12" lg="6" class="text-right">
                            <a
                                @click.prevent="prepareValue(field)"
                                @click.stop
                                href="#"
                                class="btn btn-primary font-weight-bolder"
                            >{{ $t("boost_manager.manage.add_boost") }}</a>
                            <a
                                v-if="
                          field.type === 'float' || field.type === 'integer'
                        "
                                @click.prevent="addFunction(field)"
                                @click.stop
                                href="#"
                                class="btn btn-primary font-weight-bolder ml-2"
                            >{{ $t("boost_manager.manage.add_function") }}</a
                            >
                          </v-col>
                        </v-row>
                      <div class="card card-custom gutter-b"   v-for="boost in filteredBoost(field)" :key="boost.name">
                        <div class="card-header flex-wrap py-3">
                          <div class="card-title"><h3>{{boost.value}}</h3>
                          </div>
                          <div class="card-toolbar"><p><a href="#" @click.prevent="removeValue(field, boost)">{{ $t("common.delete") }}</a></p></div>

                        </div>
                      <div class="card-body">
                          <div v-if="boost.type == 'value'">
                            <v-row align="center"
                                   justify="center">
                              <v-col cols="6" lg="3">

                                <v-switch
                                    inset
                                          v-model="boost.based"
                                          v-on:change="search">
                                  <template v-slot:label>
                                    <v-tooltip right>
                                      <template v-slot:activator="{ on }">
                                        <a v-on="on">Relevance Influence</a>
                                      </template>
                                      <span>Klikněte pro, aby se boost dostal i přes přednastavenou relevantní váhu.</span>
                                    </v-tooltip>
                                  </template>
                                </v-switch>


                              </v-col>
                              <v-col cols="6" lg="2">
                                <v-text-field
                                    v-model="boost.boost"
                                    v-on:keyup="search"
                                    single-line
                                ></v-text-field>

                                <p class="badge badge-danger" v-if="boost.boost<1">reduce visible</p>
                                <p class="badge badge-primary" v-else-if="boost.boost>1">more visible</p>
                                <p class="badge"  v-else>Váha dle relevance</p>
                              </v-col>
                              <v-col cols="12" lg="7">
                                <v-slider
                                    class="mt-7"
                                    v-model="boost.boost"
                                    :color="(boost.boost>1)?'#3699FF':((boost.boost<1)?'#F64E60':'#333')"
                                    track-color="#eee"
                                    thumb-label="always"
                                    min="0"
                                    max="20"
                                    step="0.1"
                                    v-on:change="search"
                                ></v-slider>
                              </v-col>
                            </v-row>
                          </div>
                          <div v-if="boost.type == 'functional'">

                            <v-row>
                              <v-col cols="12" lg="3">
                                <v-select
                                    v-model="boost.function"
                                    v-on:change="search"
                                    :items="['Logarithmic', 'Exponential', 'Linear']"
                                    filled
                                    label="Function"
                                    dense
                                ></v-select>
                                <p>{{ $t("boost_manager.functions.function.label_function1") }}, <a @click.prevent="hintFunction" href="#">{{ $t("boost_manager.functions.function.label_function2") }}</a></p>
                              </v-col>
                              <v-col cols="12" lg="3">
                                <v-select
                                    v-model="boost.operation"
                                    v-on:change="search"
                                    :items="['Add', 'Multiply']"
                                    filled
                                    label="Operation"
                                    dense
                                ></v-select>
                                <p>{{ $t("boost_manager.functions.function.label_operation") }}</p>

                              </v-col>
                              <v-col cols="12" lg="2">
                                <v-text-field
                                    v-model="boost.boost"
                                    v-on:keydown="search"
                                    single-line
                                ></v-text-field>

                                <p class="badge badge-danger" v-if="boost.boost<1">reduce visible</p>
                                <p class="badge badge-primary" v-else-if="boost.boost>1">more visible</p>
                                <p class="badge"  v-else>Váha dle relevance</p>
                              </v-col>
                              <v-col cols="12" lg="4">
                                <v-slider
                                    class="mt-7"
                                    v-model="boost.boost"
                                    :color="(boost.boost>1)?'#3699FF':((boost.boost<1)?'#F64E60':'#333')"
                                    track-color="#eee"
                                    thumb-label="always"
                                    min="0"
                                    max="20"
                                    step="0.1"
                                    v-on:change="search"
                                ></v-slider>
                              </v-col>
                            </v-row>
                          </div>
                      </div>
                  </div>
                    </b-card-body>
                    <div class="p-10" v-else>
                      <span>{{ $t("boost_manager.manage.no_boost_set") }}</span>
                      <div class="text-right">
                        <a
                            @click.prevent="prepareValue(field)"
                            @click.stop
                            href="#"
                            class="btn btn-primary font-weight-bolder"
                        >{{ $t("boost_manager.manage.add_boost") }}</a>
                        <a
                            v-if="
                          field.type === 'float' || field.type === 'integer'
                        "
                            @click.prevent="addFunction(field)"
                            @click.stop
                            href="#"
                            class="btn btn-primary font-weight-bolder ml-2"
                        >{{ $t("boost_manager.manage.add_function") }}</a
                        ></div>
                    </div>
                  </b-collapse>
                </b-card>
              </div>
        </v-container>
        <v-container v-else>
          <p>{{ $t("boost_manager.manage.not_available") }}</p>
        </v-container>
      </div>
      <div class="col-xl-4">
        <div class="card card-custom gutter-b bg-light">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title font-weight-bolder">{{ $t("boost_manager.modal.help.title") }}</h3>
          </div>
          <div class="card-body">
            <p>{{ $t("boost_manager.modal.help.text1") }} <a href="media/help/boost-manager.pdf" target="_blank">{{ $t("boost_manager.modal.help.text2") }}</a>.</p>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h2 class="card-label">
                {{ $t("boost_manager.preview.title") }}
              </h2>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group row">
              <div class="col-8">
                <input
                  class="form-control"
                  type="text"
                  :placeholder="$t('boost_manager.preview.search')"
                  v-on:keyup="search"
                  v-model="searchString"
                />
              </div>
              <div class="col-4">{{ $t("boost_manager.preview.time_took") }}: {{ took_time }}s</div>
            </div>
            <div
              class="card-body"
              style="overflow: auto; width:100%;height: 80vh;"
            >

              <v-progress-linear
                  v-if="loading"
                  color="blue"
                  indeterminate
              ></v-progress-linear>
              <query_results :items="previewList" type="relevance_tuning" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <schema_autocomplete :schema="boost_schema_value" :callback="addValue" />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  BoostFunction, PREVIEW_BOOSTIFY,
  PULL_FIELDS, PUT_BOOSTIFY,
} from "@/core/services/store/relevance_tuning.module";
import { mapGetters, mapState } from "vuex";
import { Boosts } from "@/core/services/store/relevance_tuning.module";
import collection_select from "@/view/pages/collections/collection_select";
import { RELOAD_MESSAGE } from "@/core/services/store/message.module";
import query_results from "@/view/pages/query-tester/results";
import schema_autocomplete from "@/view/pages/relevance-tuning/schemaAutocomplete";
import Swal from "sweetalert2";

export default {
  components: {collection_select, query_results, schema_autocomplete },
  data() {
    return {
      enableBoost: true,
      enableMandatory: false,
      filterFields: "",
      filterBoosts: [],
      filterBuffer: "",
      searchBuffer: "",
      searchString: "",
      boost_schema_value: null,
      advanced: false
    };
  },
  methods: {
    filteredBoost(field) {
      if(this.filterBoosts[field.field_name]) {
        return field.boosts.filter(item => item.value.toLowerCase().includes(this.filterBoosts[field.field_name].toLowerCase()));
      } else {
        return field.boosts;
      }
    },
    reloadData() {
      if (this.selectedCollection && this.selectedCollection.id > 0) {
        this.$store.dispatch(PULL_FIELDS, this.selectedCollection);
      }
    },
    addValue(field, value) {
      this.boost_schema_value = null;
      if (value === "") {
        return;
      }
      field.boosts.push(new Boosts(value, 1, false));
    },
    prepareValue(field) {
      this.boost_schema_value = field;
    },
    addFunction(field) {
      field.boosts.push(new BoostFunction("Logarithmic", "Multiply", 1));
    },
    removeValue(field, boost) {
      Swal.fire({
        title: "Are you sure?",
        text: `You are want delete. Are you sure?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
            if (result.value) {
              field.boosts.forEach(function (entry, index) {
                if (entry.value === boost.value) {
                  field.boosts.splice(index, 1);
                }
              });
              this.search();
            }
      });
    },
    search() {
      this.$store.dispatch(PREVIEW_BOOSTIFY, {
        collection_id: this.selectedCollection.id,
        fields: this.fields,
        q: this.searchString
      });
    },
    save() {
      Swal.fire({
        title: "Are you sure?",
        text: `By clicking on agree, this boosting will be deployed on production. Are you sure?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, save it!"
      }).then(result => {
        if (result.value) {
          this.$store
              .dispatch(PUT_BOOSTIFY, {
                fields: this.fields,
                collection_id: this.selectedCollection.id
              })
              .then(() => {
                Swal.fire(
                    "Save!",
                    `Your customers now exploration your items by new boostify tuning.`,
                    "success"
                );
              });
        }
      });
    },
    hintBoost() {
      Swal.fire({
        title: this.$t("boost_manager.functions.boost.title"),
        text: this.$t("boost_manager.functions.boost.content"),
      });
    },
    hintFunction() {
      Swal.fire({
        title: this.$t("boost_manager.functions.function.title"),
        text: this.$t("boost_manager.functions.function.content"),
      });
    }
  },
  watch: {
    changeProject() {
      this.reloadData();
    },
    changeCollection() {
      this.reloadData();
    }
  },
  computed: {
    changeCollection() {
      return this.selectedCollection.id;
    },
    changeProject() {
      return this.currentProject.token;
    },
    filteredList() {
      return this.fields.filter(item => {
        return item.field_name
            .toLowerCase()
            .includes(this.filterFields.toLowerCase());
      });
    },
    previewList() {
      return this.previews;
    },
    wildcardEnable() {
      return this.fields.wildcard_enable;
    },
    ...mapState({
      errors: state => state.relevanceTuning.errors,
      fields: state => state.relevanceTuning.fields,
      previews: state => state.relevanceTuning.previews,
      took_time: state => state.relevanceTuning.took_time,
      message: state => state.message.message,
      loading: state => state.relevanceTuning.loading,
      searchString: state => state.relevanceTuning.searchString
    }),
    ...mapGetters(["selectedCollection", "currentProject"])
  },
  beforeCreate() {
    this.$store.dispatch(RELOAD_MESSAGE);
  },
  mounted() {
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Relevance Tuning" }]);
  }
};
</script>
